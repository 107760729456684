
:root {
  --main-gap: 0px;
}

em {
    font-style: normal;
}

a.bluelink {
    @apply text-blue-500 text-center text-sm underline;
}

body {
  font-family: system-ui;
  background: white;
}
.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media screen and (min-width: 50em) {
  .wrapper {
    flex-direction: row;
  }
}
.wrapper__main {
  width: 100%;
}
.wrapper__aside {

    width: 100%;
/*  background:
          radial-gradient(black 3px, transparent 4px),
          radial-gradient(black 3px, transparent 4px),
          linear-gradient(#fff 4px, transparent 0),
          linear-gradient(45deg, transparent 74px, transparent 75px, #a4a4a4 75px, #a4a4a4 76px, transparent 77px, transparent 109px),
          linear-gradient(-45deg, transparent 75px, transparent 76px, #a4a4a4 76px, #a4a4a4 77px, transparent 78px, transparent 109px),
          #fff;
  background-size: 109px 109px, 109px 109px,100% 6px, 109px 109px, 109px 109px;
  background-position: 54px 55px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;*/
}
.minus-top-gap {
  margin-top: var(--main-gap);
}

.minus-right-gap {
  margin-right: var(--main-gap);
}

.minus-left-gap {
  margin-left: var(--main-gap);
}

.main__wrapper {

  background: white;
  display: grid;
  grid-gap: var(--main-gap);
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr auto;
  grid-template-areas:
          "nav"
          "ads"
          "bread"
          "content"
          "footer";
  & .main__header {
    grid-area: nav;
  }


  & .breadcrumb {
    grid-area: bread;
  }

  & .menu-mobile {
    grid-area: menu-mobile;
    position: fixed;
    max-width: 100%;
    z-index: 10000000000;
  }

  & .nav-mobile {
    grid-area: nav-mobile;
    display: none;
  }

  & .main__adds {
    grid-area: ads;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .main__content {
    grid-area: content;
  }

  & .main__sidebar__left {
    display: none;
  }

  & .main__sidebar__right {
    display: none;
  }
}

.header__bardiv {
display: none;

}

.header__barspan {
  display: block;
}

.skin__left, .skin__right {
    display: none;
    position: sticky;
    top:0;
    height: 100vh;
}
.logo-turfy {
    height: 2rem;
}

#pubdroitebasbas, #pubdroitehaut, #pubdroitebas {
display: none;
}
.stickymenu {
    display: none;
    background: white;
    position: sticky;
    top:0rem;
    z-index: 1;
    padding: 0.3rem;
    margin: 0;
}

.red-link {
    @apply bg-turfoo-red text-white font-semibold p-2 rounded-lg text-center max-w-2xl mx-auto
}

.green-link {
    @apply bg-turfoo-green text-white font-semibold p-2 rounded-lg text-center max-w-2xl mx-auto
}

@media screen and (min-width: 60em) {
    .header__bardiv {
        width: 728px;
        height: 90px;
        /*    border: 2px solid black;
            background: url('https://chiddenfr1.img.sputniknews.com/images/102988/88/1029888812.jpg');*/
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: 50% 35%;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
    }

}
@media screen and (min-width: 50em) {
    .wrapper__aside {
        min-width: 300px;
        max-width: 300px;
        min-height: 400px;
        margin: 0 0 0 1rem;
        padding: 0;
    }
    #pubdroitebasbas, #pubdroitehaut, #pubdroitebas {
        display: block;
    }
    .stickymenu {
        display: flex;

    }


  .header__barspan {
   /*display: none;*/
  }
}
@media screen and (min-width: 30em) {
    .logo-turfy {
        height: 3.3rem;
    }
  .main__wrapper {
    background: white;
    display: grid;
    min-height: 100vh;
    grid-template-columns: 0fr 1fr 0fr;
    grid-template-rows: 100px auto auto 1fr auto;
    grid-template-areas:
            "nav-left nav nav-right"
            "ads ads ads"
            "bread-left bread bread-right"
            "sidebar-left content sidebar-right"
            "sidebar-left footer sidebar-right";
& .main__header {
    grid-column: 2 /  3 ;
  }

& .menu-mobile {
    grid-area: menu-mobile;
    position: fixed;
    max-width: 350px;
    z-index: 2;
    border-right: 1px solid;
    box-shadow: 20px 0px 33px 11px #0009;
  }

& .breadcrumb {
    grid-area: bread;
  }

& .nav-left {
    grid-area: nav-left;
  }

& .nav-right {
    grid-area: nav-right;
    grid-row-start: 1;
    grid-row-end: 4;
  }

& .bread-left {
    grid-area: bread-left;
  }

& .bread-right {
    grid-area: bread-right;
  }

& .main__adds {
    grid-area: ads;
    display: flex;
    justify-content: center;
    align-items: center;
  }

& .main__content {
    grid-area: content;
    padding: 10px;
  }

& .main__sidebar__left {
    grid-area: sidebar-left;
    display: grid;
    justify-content: center;
  }

& .main__sidebar__right {
    grid-area: sidebar-right;
    display: grid;
    justify-content: center;
  }
}
}



@media screen and (min-width: 100em) {

  .main__wrapper {
    background: white;
    display: grid;
    min-height: 100vh;
    grid-template-columns: 0.9fr 4fr 0.9fr;
    grid-template-rows: 100px auto auto 1fr auto;
    grid-template-areas:
            "nav-left nav nav-right"
            "ads ads ads"
            "bread-left bread bread-right"
            "sidebar-left content sidebar-right"
            "sidebar-left footer sidebar-right";
& .main__header {
    grid-column: 2 /  3 ;
  }

& .menu-mobile {
    grid-area: menu-mobile;
    position: fixed;
    max-width: 350px;
    z-index: 2;
    border-right: 1px solid;
    box-shadow: 20px 0px 33px 11px #0009;
  }

& .breadcrumb {
    grid-area: bread;
  }

& .skin__left {
    display: block;
      grid-area: sidebar-left;
    background-repeat: no-repeat !important;
      background-position-x: right !important;
  }

& .skin__right {
      display: block;
      grid-area: sidebar-right;
      background-repeat: no-repeat !important;
      background-position-x: left !important;
  }

& .nav-left {
    grid-area: nav-left;
  }

& .nav-right {
    grid-area: nav-right;
    grid-row-start: 1;
    grid-row-end: 4;
  }

& .bread-left {
    grid-area: bread-left;
  }

& .bread-right {
    grid-area: bread-right;
  }

& .main__adds {
    grid-area: ads;
    display: flex;
    justify-content: center;
    align-items: center;
  }

& .main__content {
    grid-area: content;
    padding: 10px;
  }

& .main__sidebar__left {
    grid-area: sidebar-left;
    display: grid;
    justify-content: center;
  }

& .main__sidebar__right {
    grid-area: sidebar-right;
    display: grid;
    justify-content: center;
  }
}
}

.main__adds.minus-top-gap.p-2.white.bg-turfoo-red a {
  color: white;
  text-decoration: none;
}

.svg-date {
  width: 70px;
  height: 70px;
  position: relative;
}
.vignette {
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 3rem;
}

.mini-me {
  border: 1px solid white;
  outline: 1px solid grey;
}

.mini-me:hover {
  transform: scale(1.2);
}


/*! Menu mobile */

#navButton {
  border: none;
  background: transparent;
}

.menu-mobile {
  position: relative;
}

.menu-mobile a {
  color:black;
  text-decoration: none;
  font-weight: 600;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-mobile a:hover {
  @apply text-turfoo-green
}

#parier a {
  display: flex;
  justify-content: center;
  align-items: center;
}

#parier a:hover {
  color:white;
}

.social a {
  justify-content: center;
}

.social a.social-facebook:hover svg {
  color: #3b5998;
}

.social a.social-twitter:hover svg {
  color: #1da1f2;
}

.social a.social-google:hover svg {
  color: #dd4b39;
}

.comeBack, .comeBack2 {
  color: green;
}

.level1 li, .level2 li, .level3 li {
  padding: 0 0 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  border-bottom: 1px solid gray;
}

span.has-sublevel1, span.has-sublevel2, span.has-sublevel3, span.has-sublevel4, span.has-sublevel5 {
  border-left: 1px solid grey;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18%;
  cursor: pointer;
}

span.has-sublevel1:hover, span.has-sublevel2:hover, span.has-sublevel3:hover, span.has-sublevel4:hover, span.has-sublevel5:hover {
    @apply bg-turfoo-green
}

span.close-me-now {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  cursor: pointer;
}

span.close-me-now:hover {
  color: green;
}

[aria-expanded="false"] .close {
  display: none;
}

[aria-expanded="true"] .open {
  display: none;
}


/* nav footer */

.active__footer__menu {
    @apply text-turfoo-green;
}


/* desktop menu */

.dropdownmenu ul, .dropdownmenu li {
  margin: 0;
  padding: 0;
}
.dropdownmenu ul {
  list-style: none;
  width: 100%;
}
.dropdownmenu li {
  float: left;
  position: relative;
  width:auto;
}
.dropdownmenu a {
  color: #FFFFFF;
  display: block;
  padding: 8px 8px;
  text-align: center;
  text-decoration: none;
  transition: all .25s ease;
}
.dropdownmenu li:hover a {
    @apply bg-turfoo-green;
}
#submenu {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 35px;
  visibility: hidden;
  z-index: 1;
}
li:hover ul#submenu {
  opacity: 1;
  top: 40px;	/* adjust this as per top nav padding top & bottom comes */
  visibility: visible;
  min-width:200px;
}
#submenu li {
  float: none;
  width: 100%;
}


/* night mode */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.lastminute-article:nth-child(odd) {
  background: #f5f5f5;
}
#record table:not(#infos_cheval):not(#carriere_cheval) {
  margin-left: auto;
  margin-right:auto;
}

#record tr > .informationscourse {
  display: block;
  margin-bottom: 2rem;
  padding:0.4rem;
}

#record td h2 {
  font-size: 15px;
  height: 36px;
  padding-top: 2px;
  margin: 0px;
  color: black;
  background: white;
  line-height: 36px;
  box-shadow: 0 5px 20px #00000026;
  border-radius: 5px;
  margin: 2rem;
}

#record td {
  line-height:25px
}

#record td a{
    color: initial;
    text-decoration: none;
}

#record td a:hover{
    text-decoration: underline;
    text-decoration-color: inherit;
}

/* fiches.css:23 */
#infos_cheval {
  margin-bottom: 2rem;
  width: 80%;
}
#infos_cheval td {
  padding: 0.5rem;
}

#infos_cheval td:nth-child(1) {
    font-weight: bold;
}

#infos_cheval td:nth-child(2) {
  text-align: right;
}

#infos_cheval tr:nth-child(odd) {
  background: #f5f5f5;
}

#record #carriere_cheval {
  margin-bottom: 3rem;
  width: 80%;
}
#record .selected_horse {
  color: #ab0012;
  font-weight: bold;
}
#record .entete{background-color: #f5f5f5;}


#carriere_cheval th {
  width: auto!important;
  font-size: 0.7rem;
}

#carriere_cheval tr:not(:first-child) td {
  padding: 0.4rem;
}

#courses_courues, #quinte_courues, #quinte_victoires, #quinte_places, #courses_victoires, #courses_places {
  text-align: center;
}

table.arriveescourse {
  background: white;
  width: 100%;
  height:100%;
  text-align: center;
  transition: all 1s;
  font-weight: normal;
  font-kerning: normal;
  letter-spacing: normal;
  font-size: 0.7rem;
}

.arriveescourse:hover {
  background: #73b61640;
}


table.arriveescourse tr:nth-child(even) {
  background: #f5f5f5 ;
  width: 100%;
}

table.arriveescourse tr:nth-child(2) {
  display: none;
}


td.informationscourse {
  font-size: 0.9em;
  background-color: #eaeaea;
  margin-bottom: 10px;
  border-top: solid #FFF9F9 1px;
  padding-top: 4px;
  margin-bottom: 10px;
}

.informationscourse br {
  display:block;
  height: 4rem;
}

#record a.fiches {
  text-decoration: none;
  background-color: green;
  line-height: 34px;
  height: 34px;
  display: block;
  color: white;
  font-size: 14px;
  margin: 10px;
}

#record center:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#record center:nth-child(2) a {
  display: block;
  border: 1px solid black;
  padding: 0.4rem;
  text-decoration: none;
  color: black;
  border-radius: 4px;
  background: #ddfbdd;
}

#record center:nth-child(2) a:hover {
  background: dimgrey;
  color: white;
}

#record center:nth-child(2) br + a {
  margin-top: 1rem;
}

.cc-window {
  font-size: 12px !important;
}

div#pub {
  position: fixed;
  bottom: 63px;
  height: 50px;
  background-color: #990000;
  width: 100%;
}

@media screen and (min-width: 30em) {
    div#pub {
        display: none;
    }
}

@media (--breakpoint-large) {
  div#pub {
    bottom: 0;
  }
}

.casaque {
  width: 2rem;
  height: 2.3rem;
}

.month-content {
    max-width: 320px;
    margin: auto;
    border: 1px solid grey;
}

.b--turfoo-green.ba.bw2.turfoo-green {
    font-size: .9rem;
    border-width: 0.15rem;
}

.b--turfoo-green.ba.bw2.turfoo-green:hover {
    filter: brightness(80%);
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@keyframes fade-out {
    to {
        opacity: 0;
    }
}

@keyframes slide-from-bottom {
    from {
        transform: translateY(50px);
    }
}

@keyframes slide-to-top {
    to {
        transform: translateY(-50px);
    }
}

::view-transition-old(root) {
    animation:
            90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
            300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-top;
}

::view-transition-new(root) {
    animation:
            210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
            300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-bottom;
}