.main__wrapper {
  & .main__footer {
    grid-area: footer;
    padding: 10px;
    background: lightgray;
    display: grid;
    grid-template-rows: 0.4fr 0.4fr 0.5fr 1fr 1.5fr 0.5fr 0.5fr;
    grid-template-areas: "news" "newsOptions" "links" "warning" "description" "copyright" "night-mode" ;
    justify-items: center;
    align-content: center;

    & .main__footer__news {
      grid-area: news;
    }

    & .main__footer__news__options {
      grid-area: newsOptions;
      & a {
        color: var(--gray);
      }
    }

    & .main__footer__links {
      grid-area: links;

      & a {
        color: var(--gray);
      }
    }

    & .main__footer__warning {

      grid-area: warning;
      display: grid;
      align-items: center;
      grid-template-columns:1fr 7fr;
      grid-template-areas: "img text";

      & img {
        width: 38px;
        grid-area: img;
      }
    }

    & .main__footer__description {
      grid-area: description;

      & a {
        color: var(--gray);
      }
    }

    & .main__footer__copyRight {
      grid-area: copyright;
      color: var(--gray);
    }
  }
}

@media (--breakpoint-large) {
  .main__wrapper {
    & .main__footer {
      grid-template-rows: .7fr .7fr 0.4fr 1fr .7fr;
    }
  }
}




