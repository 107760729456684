@import "../css/base.css";
@import "../css/footer.css";
@import "news.css";
@import "pagination.css";

.page__home {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-areas:
          "result"
          "ad1"
          "bettingTips"
          "ad2"
          "news"
          "ad3"
          "next-turf"
          "last-today";
}

.ban_turfoo {
    width: 100%;
    height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
}
.page__home__part__result {
  grid-area: result;
}

.page__home__part__bettingTips {
  grid-area: bettingTips;
}

.page__home__part__news {
  grid-area: news;
}

.page__home__part__next-turf {
  grid-area: next-turf;
}

.page__home__part__last-today {
  grid-area: last-today;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
}


@media screen and (min-width: 150em) {
  .page__home {
    grid-template-columns: 1fr 1fr ;
    grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-areas:
            "result bettingTips"
            "news news"
            "next-turf next-turf"
            "last-today last-today"
  }
  .page__home__part__last-today {
    grid-template-columns: 1fr;
  }

}
.page__home__part__last-today article:last-of-type {
    margin-bottom: 3rem;
}
.page__home__part__result__elements {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (--breakpoint-large) {
  .page__home__part__result__elements {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}