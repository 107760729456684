
.page__actualites__top__article {
    display: grid;
    grid-template-columns: 100px 1fr;
    margin-bottom: .5rem;
}

.page__actualites__top__article__image img {
    width: 100px;
    height: 66px;
}


@media (min-width: 880px) {
    .page__actualites__top__article {
        display: grid;
        grid-template-columns: 150px 1fr;
    }

    .page__actualites__top__article__image img {
        width: 148px;
        height: 98px;
    }
}

@media (min-width: 1280px) {
    .page__actualites__top__article {
        display: grid;
        grid-template-columns: 200px 1fr;
    }

    .page__actualites__top__article__image img {
        width: 248px;
        height: 132px;
    }
}


.page__actualites__bottom__article {
    display: grid;
    grid-template-columns: 150px 1fr;
}

.page__actualites__bottom__article__image img {
    width: 148px;
    height: 98px;
}

@media (min-width: 880px) {
    .page__actualites__bottom__article {
        display: grid;
        grid-template-columns: 200px 1fr;
    }

    .page__actualites__bottom__article__image img {
        width: 100%;
        height: 100%;
    }
}


